import React from "react";

export default function EuroFormat({ amount }) {
  return (
    <>
      {new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "eur",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(amount)}
    </>
  );
}
