import axios from "axios";
import PubSub from "pubsub-js";
import qs from "qs";

export const login = async (username, password) => {
  try {
    const { success, error, session_hash, is_admin } = (
      await axios.post(
        `/auth.php`,
        qs.stringify({
          username,
          password,
        })
      )
    ).data;

    if (success && is_admin && Number(is_admin) === 1) {
      // im Store speichern
      localStorage.setItem("x-auth-token", session_hash);
      return { success: 1 };
    } else if (Number(is_admin) !== 1) {
      return { error: "Nur Administratoren können sich einloggen." };
    }

    return { error };
  } catch {
    return { error: "Fehler beim Einloggen." };
  }
};

export const logout = () => {
  localStorage.removeItem("x-auth-token");
};

export const checkSessionValid = (xAuthToken) =>
  axios.post("/check_session.php", qs.stringify({ sessionHash: xAuthToken }));

export const checkIfCurrentSessionExistsAndIsValid = async () => {
  const xAuthToken = localStorage.getItem("x-auth-token");

  if (xAuthToken) {
    const { is_valid: valid } = (await checkSessionValid(xAuthToken)).data;

    if (valid && Number(valid) === 1) {
      return true;
    }
  }

  return false;
};

export const setSelectedShoppingCenter = (shoppingCenter) => {
  PubSub.publish("SELECTED_SHOPPING_CENTER", { shoppingCenter });
  sessionStorage.setItem(
    "selected-shopping-center",
    JSON.stringify(shoppingCenter)
  );
};

export const getSelectedShoppingCenter = () => {
  const selectedShoppingCenter = sessionStorage.getItem(
    "selected-shopping-center"
  );

  if (selectedShoppingCenter) {
    return JSON.parse(selectedShoppingCenter);
  }

  return null;
};
