import axios from "axios";
import qs from "qs";
import { getSelectedShoppingCenter } from "./session";

export const apiGet = (object, endpoint, params) => {
  const authToken = localStorage.getItem("x-auth-token");
  const selectedShoppingCenter = getSelectedShoppingCenter();

  let headers = {};
  if (authToken != null) {
    headers["x-auth-token"] = authToken;
  }

  return axios.get(`/api.php`, {
    params: {
      object,
      endpoint,
      ...params,
      scId: selectedShoppingCenter ? selectedShoppingCenter.id : null,
    },
    headers,
    timeout: 10000,
  });
};

export const apiPost = (object, endpoint, params) => {
  const authToken = localStorage.getItem("x-auth-token");
  const selectedShoppingCenter = getSelectedShoppingCenter();

  let headers = {};
  if (authToken != null) {
    headers["x-auth-token"] = authToken;
  }

  return axios.post(
    "/api.php",
    qs.stringify({
      object,
      endpoint,
      ...params,
      scId: selectedShoppingCenter ? selectedShoppingCenter.id : null,
    }),
    {
      headers,
      timeout: 10000,
    }
  );
};
