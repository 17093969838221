import React, { Component } from "react";
import { Card, Col, Row } from "antd";
import LetzteAktivitaeten from "../LetzteAktivitaeten";
import AktuelleGutscheinAnzahlen from "./AktuelleGutscheinAnzahlen";

class Dashboard extends Component {
  render() {
    return (
      <>
        <h1>Gutscheinverwaltung</h1>
        <Row gutter={24} style={{ marginBottom: "24px" }}>
          <Col xs={24} lg={12}>
            <AktuelleGutscheinAnzahlen />
          </Col>
        </Row>
        <Card className="box-shadow">
          <LetzteAktivitaeten />
        </Card>
      </>
    );
  }
}

export default Dashboard;
