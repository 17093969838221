import { message, Table } from "antd";
import dayjs from "dayjs";
import PubSub from "pubsub-js";
import React, { Component } from "react";
import { getSelectedShoppingCenter } from "../../api/session";
import { getAllVouchers } from "../../api/voucher";
import EuroFormat from "../Util/EuroFormat";

class Gutscheinliste extends Component {
  state = {
    loading: false,
    vouchers: [],
    currentPage: 1,
    currentPageSize: 25,
    totalVouchersAmount: 0,
  };

  loadVouchers = async () => {
    this.setState({ loading: true });
    const { currentPage, currentPageSize } = this.state;
    const { success, error, vouchers, totalVouchers } = (
      await getAllVouchers((currentPage - 1) * currentPageSize, currentPageSize)
    ).data;

    if (success) {
      this.setState({
        loading: false,
        vouchers,
        totalVouchersAmount: totalVouchers,
      });
    } else {
      this.setState({ loading: false, vouchers: [], totalVouchersAmount: 0 });
      message.error(`Fehler beim Laden der Gutscheine: ${error}`);
    }
  };

  componentDidMount = () => {
    PubSub.subscribe("SELECTED_SHOPPING_CENTER", () => {
      this.loadVouchers();
    });

    if (getSelectedShoppingCenter() != null) {
      this.loadVouchers();
    }
  };

  componentWillUnmount = () => {
    PubSub.clearAllSubscriptions();
  };

  render() {
    const {
      loading,
      vouchers,
      currentPage,
      currentPageSize,
      totalVouchersAmount,
    } = this.state;

    return (
      <Table
        size="small"
        dataSource={vouchers}
        loading={loading}
        columns={[
          {
            title: "EAN",
            dataIndex: "ean",
            width: 180,
            className: "font-monospace",
          },
          {
            title: "Hinzugefügt am",
            dataIndex: "date_added",
            render: (date) => `${dayjs(date).format("DD.MM.YYYY, HH:mm")} Uhr`,
          },
          {
            title: "Betrag",
            dataIndex: "eur_value",
            render: (eur) => <EuroFormat amount={Number(eur) / 100} />,
            align: "right",
            className: "font-monospace",
            width: 80,
          },
          {
            title: "Verkauft am",
            dataIndex: "voucher_sold_date",
            render: (date) =>
              date != null ? (
                `${dayjs(date).format("DD.MM.YYYY, HH:mm")} Uhr`
              ) : (
                <small className="text-secondary">noch nicht verkauft</small>
              ),
          },
          {
            title: "Eingelöst am",
            dataIndex: "voucher_redemption_date",
            render: (date) =>
              date != null ? (
                `${dayjs(date).format("DD.MM.YYYY, HH:mm")} Uhr`
              ) : (
                <small className="text-secondary">noch nicht eingelöst</small>
              ),
          },
        ]}
        pagination={{
          current: currentPage,
          pageSize: currentPageSize,
          pageSizeOptions: ["25", "50", "100"],
          onChange: (page, size) => {
            if (currentPage !== page || currentPageSize !== size) {
              this.setState(
                { currentPage: page, currentPageSize: size },
                this.loadVouchers
              );
            }
          },
          total: totalVouchersAmount,
          showTotal: (t, r) =>
            `Zeige Gutscheine ${r[0]} bis ${r[1]} von insgesamt ${t} Gutscheinen`,
        }}
      />
    );
  }
}

export default Gutscheinliste;
