import React, { Component } from "react";
import { Col, ConfigProvider, message, Row, Spin } from "antd";
import deDE from "antd/lib/locale/de_DE";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  checkIfCurrentSessionExistsAndIsValid,
  getSelectedShoppingCenter,
  setSelectedShoppingCenter,
} from "./api/session";
import "./App.css";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import Navigation from "./components/Navigation";
import { getAllShoppingCenters } from "./api/shoppingCenter";
import Gutscheinliste from "./components/Gutscheinliste";

class App extends Component {
  state = {
    loadingSession: false,
    loggedIn: false,
    selectedShoppingCenterId: null,
    shoppingCenters: [],
  };

  loadShoppingCenters = async () => {
    const {
      success,
      error,
      shopping_centers: sc,
    } = (await getAllShoppingCenters(true)).data;

    if (success && sc) {
      this.setState({ shoppingCenters: sc });
    } else {
      message.error(`Fehler beim Laden der Einkaufszentren: ${error}`);
    }
  };

  componentDidMount = async () => {
    // checken, ob es eine session gibt und anzeigen
    if (localStorage.getItem("x-auth-token") != null) {
      this.setState({ loadingSession: true });
      const isValid = await checkIfCurrentSessionExistsAndIsValid();
      if (isValid) {
        this.setState({ loggedIn: true });

        // einkaufszentren laden
        this.loadShoppingCenters();
      }
      this.setState({ loadingSession: false });
    }

    // ausgewähltes shopping-center abfragen
    const selectedShoppingCenter = getSelectedShoppingCenter();

    if (selectedShoppingCenter) {
      this.setState({
        selectedShoppingCenter,
      });
    }
  };

  render() {
    const {
      loggedIn,
      loadingSession,
      selectedShoppingCenter,
      shoppingCenters,
    } = this.state;

    if (loadingSession)
      return (
        <Spin spinning size="large" tip="Lade Sitzung ...">
          <div style={{ display: "flex", height: "100vh", width: "100vw" }}>
            &nbsp;
          </div>
        </Spin>
      );

    if (!loggedIn)
      return (
        <Login
          onSuccess={() => {
            this.setState({ loggedIn: true }, this.loadShoppingCenters);
          }}
        />
      );

    return (
      <ConfigProvider locale={deDE}>
        <Router>
          <Navigation
            selectedShoppingCenterId={
              selectedShoppingCenter ? selectedShoppingCenter.id : null
            }
            onSelectShoppingCenter={(shoppingCenter) => {
              this.setState({ selectedShoppingCenter: shoppingCenter }, () => {
                setSelectedShoppingCenter(shoppingCenter);
              });
            }}
            shoppingCenters={shoppingCenters}
          />
          <Row gutter={0}>
            <Col
              xs={{ span: 22, offset: 1 }}
              md={{ span: 20, offset: 2 }}
              xxl={{ span: 18, offset: 3 }}
              style={{ padding: "24px 0" }}
            >
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/gutscheinliste" element={<Gutscheinliste />} />
              </Routes>
            </Col>
          </Row>
        </Router>
      </ConfigProvider>
    );
  }
}

export default App;
