import React, { Component } from "react";
import { Button, Card, Input, message, Space } from "antd";
import { login } from "../../api/session";
import "./login.css";
import Logo from "../../images/gutscheinverwaltung.svg";

class Login extends Component {
  state = { loading: false, error: null, username: "", password: "" };

  handleLogin = async () => {
    const { username, password } = this.state;
    this.setState({ loading: true });
    if (username && password) {
      const { success, error } = await login(username, password);

      if (success) {
        const { onSuccess } = this.props;
        message.success("Eingeloggt.");
        if (onSuccess) onSuccess();
      } else {
        message.error(error);
        this.setState({ loading: false });
      }
    } else {
      message.error("Benutzername und Passwort angeben.", 2, () => {
        this.setState({ loading: false });
      });
    }
  };

  render() {
    const { loading, username, password } = this.state;

    return (
      <div className="fullscreen-container">
        <Card
          className="box-shadow login-card"
          bodyStyle={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img src={Logo} alt="Logo" title="Logo" className="logo" />
          <Space direction="vertical" style={{ width: "100%" }}>
            <Input
              value={username}
              onChange={(e) => {
                this.setState({ username: e.target.value });
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  this.handleLogin();
                }
              }}
              placeholder="Benutzername"
            />

            <Input
              type="password"
              value={password}
              onChange={(e) => {
                this.setState({ password: e.target.value });
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  this.handleLogin();
                }
              }}
              placeholder="Passwort"
            />

            <Button
              block
              type="primary"
              loading={loading}
              onClick={this.handleLogin}
            >
              Anmelden
            </Button>
          </Space>
        </Card>
      </div>
    );
  }
}

export default Login;
