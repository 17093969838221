import React from "react";

export default function NumberFormat({ number, fractionDigits }) {
  return (
    <>
      {new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: fractionDigits || 0,
        maximumFractionDigits: fractionDigits || 2,
      }).format(number)}
    </>
  );
}
