import { green, red } from "@ant-design/colors";
import { LeftSquareTwoTone, RightSquareTwoTone } from "@ant-design/icons";
import { message, PageHeader, Table } from "antd";
import dayjs from "dayjs";
import PubSub from "pubsub-js";
import React, { Component } from "react";
import { getSelectedShoppingCenter } from "../../api/session";
import { getActivityLog } from "../../api/shoppingCenter";

class LetzteAktivitaeten extends Component {
  state = {
    loading: false,
    selectedShoppingCenter: null,
    activityLog: [],
    currentPage: 1,
    currentPageSize: 25,
    totalActivityEntries: 0,
  };

  loadActivityLog = async () => {
    const { selectedShoppingCenter } = this.state;

    if (selectedShoppingCenter) {
      this.setState({ loading: true });
      const { currentPage, currentPageSize } = this.state;

      const { success, error, log, totalEntries } = (
        await getActivityLog(
          (currentPage - 1) * currentPageSize,
          currentPageSize
        )
      ).data;

      if (success) {
        this.setState({
          loading: false,
          activityLog: log,
          totalActivityEntries: totalEntries,
        });
      } else {
        this.setState({
          loading: false,
          activityLog: [],
          totalActivityEntries: 0,
        });
        message.error(error);
      }
    }
  };

  componentDidMount = () => {
    const selectedShoppingCenter = getSelectedShoppingCenter();
    if (selectedShoppingCenter) {
      this.setState({ selectedShoppingCenter }, this.loadActivityLog);
    }

    PubSub.subscribe("SELECTED_SHOPPING_CENTER", (topic, data) => {
      this.setState(
        { selectedShoppingCenter: data.shoppingCenter },
        this.loadActivityLog
      );
    });
  };

  componentWillUnmount = () => {
    PubSub.clearAllSubscriptions();
  };

  render() {
    const {
      loading,
      selectedShoppingCenter,
      activityLog,
      totalActivityEntries,
      currentPage,
      currentPageSize,
    } = this.state;

    return (
      <PageHeader
        title="Letzte Aktivitäten"
        subTitle={`im ausgewählten Einkaufszentrum ${
          selectedShoppingCenter
            ? selectedShoppingCenter.shopping_center_name
            : ""
        }`}
      >
        {selectedShoppingCenter ? (
          <Table
            loading={loading}
            rowKey="id"
            dataSource={activityLog}
            pagination={{
              total: totalActivityEntries,
              current: currentPage,
              pageSize: currentPageSize,
              pageSizeOptions: ["25", "50", "100"],
              onChange: (page, size) => {
                const { currentPage, currentPageSize } = this.state;
                if (currentPage !== page || currentPageSize !== size) {
                  this.setState(
                    { currentPage: page, currentPageSize: size },
                    this.loadActivityLog
                  );
                }
              },
              showTotal: (total, range) =>
                `Einträge ${range[0]} bis ${range[1]} von insgesamt ${total} Einträgen werden angezeigt.`,
            }}
            columns={[
              {
                title: "Datum",
                dataIndex: "log_date",
                render: (date) => dayjs(date).format("DD.MM.YYYY HH:mm"),
                align: "right",
                width: 200,
              },
              {
                title: "Typ",
                dataIndex: "activity_type",
                render: (type) => {
                  switch (type) {
                    case "sell":
                      return (
                        <>
                          <RightSquareTwoTone twoToneColor={green[5]} /> Verkauf
                        </>
                      );
                    case "redeem":
                      return (
                        <>
                          <LeftSquareTwoTone twoToneColor={red[5]} /> Rücknahme
                        </>
                      );
                    default:
                      return "-";
                  }
                },
              },
              {
                title: "Durchgeführt von",
                key: "activity_user",
                render: (activity) =>
                  `${activity.activity_user.first_name || ""} ${
                    activity.activity_user.last_name || ""
                  }`,
              },
              {
                title: "Anzahl Gutscheine",
                key: "vouchers_amount",
                render: (activity) => {
                  if (activity.activity_type === "sell") {
                    return activity.sell.vouchers.length;
                  } else if (activity.activity_type === "redeem") {
                    return activity.redemption.vouchers.length;
                  }
                },
                align: "center",
                width: 100,
              },
              {
                title: "Gesamtbetrag",
                key: "total_amount",
                render: (activity) => {
                  if (activity.activity_type === "sell") {
                    return new Intl.NumberFormat("de-DE", {
                      style: "currency",
                      currency: "EUR",
                    }).format(Number(activity.sell.total_eur_value) / 100);
                  } else if (activity.activity_type === "redeem") {
                    return new Intl.NumberFormat("de-DE", {
                      style: "currency",
                      currency: "EUR",
                    }).format(
                      Number(activity.redemption.total_eur_value) / 100
                    );
                  }
                },
                align: "right",
              },
            ]}
            locale={{ emptyText: "Keine Aktivitäten vorhanden." }}
          />
        ) : (
          <b>Kein Einkaufszentrum ausgewählt.</b>
        )}
      </PageHeader>
    );
  }
}

export default LetzteAktivitaeten;
