import { Alert, Card, Descriptions, Spin } from "antd";
import PubSub from "pubsub-js";
import React, { Component } from "react";
import { getSelectedShoppingCenter } from "../../api/session";
import { getCurrentVoucherAmounts } from "../../api/voucher";
import EuroFormat from "../Util/EuroFormat";
import NumberFormat from "../Util/NumberFormat";

class AktuelleGutscheinAnzahlen extends Component {
  state = { loading: false, error: null, count: null };

  loadNumbers = async () => {
    this.setState({ loading: true });
    const { success, error, count } = (await getCurrentVoucherAmounts()).data;

    if (success && count) {
      this.setState({ loading: false, count });
    } else {
      this.setState({ loading: false, error });
    }
  };

  componentDidMount = () => {
    const selectedShoppingCenter = getSelectedShoppingCenter();

    if (selectedShoppingCenter) {
      this.loadNumbers();
    }

    PubSub.subscribe("SELECTED_SHOPPING_CENTER", () => {
      this.loadNumbers();
    });
  };

  componentWillUnmount = () => {
    PubSub.clearAllSubscriptions();
  };

  render() {
    const { loading, error, count } = this.state;

    const { style } = this.props;

    return (
      <Card
        title="Aktuelle Anzahl an Gutscheinen"
        bodyStyle={{ padding: 0 }}
        style={style}
        className="box-shadow"
      >
        <Spin spinning={loading}>
          {error && (
            <Alert
              type="error"
              showIcon
              message={error}
              style={{ marginBottom: "12px" }}
            />
          )}
          {count && (
            <Descriptions
              column={2}
              bordered
              contentStyle={{ width: "110px", textAlign: "right" }}
            >
              {count.total && (
                <Descriptions.Item label="Gesamtanazhl">
                  <NumberFormat number={count.total} />
                </Descriptions.Item>
              )}
              {count.sold && (
                <Descriptions.Item label="Verkauft">
                  <NumberFormat number={count.sold} />
                </Descriptions.Item>
              )}
              {count.redeemed && (
                <Descriptions.Item label="Eingelöst">
                  <NumberFormat number={count.redeemed} />
                </Descriptions.Item>
              )}
              {count.sold && count.redeemed && (
                <Descriptions.Item label="Derzeit im Umlauf">
                  <NumberFormat
                    number={Number(count.sold) - Number(count.redeemed)}
                  />
                </Descriptions.Item>
              )}
              {count.sold && count.redeemed && (
                <Descriptions.Item label={<b>Kassastand</b>}>
                  <EuroFormat
                    amount={(Number(count.sold) - Number(count.redeemed)) * 10}
                  />
                </Descriptions.Item>
              )}
            </Descriptions>
          )}
        </Spin>
      </Card>
    );
  }
}

export default AktuelleGutscheinAnzahlen;
