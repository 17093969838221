import React from "react";
import { Button, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import {
  BankOutlined,
  DashboardFilled,
  LogoutOutlined,
  OrderedListOutlined,
} from "@ant-design/icons/lib/icons";
import { logout } from "../../api/session";

const Navigation = ({
  selectedShoppingCenterId,
  onSelectShoppingCenter,
  shoppingCenters,
}) => {
  const location = useLocation();
  const selectedShoppingCenter =
    selectedShoppingCenterId && Array.isArray(shoppingCenters)
      ? shoppingCenters.find(
          (sc) => Number(sc.id) === Number(selectedShoppingCenterId)
        )
      : null;
  return (
    <Menu
      mode="horizontal"
      selectedKeys={[location.pathname]}
      style={{ alignItems: "center" }}
      className="box-shadow"
      onSelect={({ key, keyPath }) => {
        if (
          keyPath &&
          keyPath.length >= 1 &&
          keyPath[1] === "einkaufszentrum"
        ) {
          const scId = Number(key);
          const shoppingCenter = shoppingCenters.find(
            (sc) => Number(sc.id) === scId
          );
          onSelectShoppingCenter(shoppingCenter);
        }
      }}
    >
      <Menu.Item key="/" icon={<DashboardFilled />}>
        <Link to="/">Dashboard</Link>
      </Menu.Item>
      <Menu.Item key="/gutscheinliste" icon={<OrderedListOutlined />}>
        <Link to="/gutscheinliste">Gutscheinliste</Link>
      </Menu.Item>
      <Menu.SubMenu
        key="einkaufszentrum"
        title={
          selectedShoppingCenter
            ? selectedShoppingCenter.shopping_center_name
            : "Einkaufszentrum wählen ..."
        }
        style={{ marginLeft: "auto" }}
        icon={<BankOutlined />}
      >
        {shoppingCenters &&
          shoppingCenters
            .sort((sc1, sc2) =>
              sc1.shopping_center_name.localeCompare(sc2.shopping_center_name)
            )
            .map((sc) => (
              <Menu.Item key={sc.id}>{sc.shopping_center_name}</Menu.Item>
            ))}
      </Menu.SubMenu>
      <Menu.Item key="logout">
        <Button
          type="link"
          icon={<LogoutOutlined />}
          onClick={() => {
            logout();
            window.location.reload();
          }}
        >
          Abmelden
        </Button>
      </Menu.Item>
    </Menu>
  );
};

export default Navigation;
